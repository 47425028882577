// Navigation Customization
.simplebar-max-height {
  max-height: 200px;
}
.side-nav {
  .side-nav-link {
    &:hover,
    &:focus {
      background-color: $darkBlue;
      color: $menu-item;
    }
    &:active {
      color: $white;
      font-weight: $font-weight-bold;
    }

    i {
      margin: 0 10px 0 0;
      font-size: 1.1rem;
    }

    font-size: 1rem;
  }

  .menu-arrow {
    font-size: 1.1rem;
  }

  .side-nav-title {
    color: $menu-item;
    font-weight: $font-weight-bold;
  }

  .mm-active {
    background-color: $darkBlue;
    > a {
      color: $menu-item !important;
    }
  }
}

#mobile_menu_button {
  #mobile-menu-open {
    display: none;
  }
  #mobile-menu-closed {
    display: block;
  }
}

.topbar-middle-logo {
  display: none;
}

.sidebar-enable {
  .mobile-menu-enable {
    #mobile-menu-open {
      display: block !important;
    }
    #mobile-menu-closed {
      display: none !important;
    }
  }
}

.non-menu-logo {
  position: relative;
  width: 100%;
}

.max-width-100 {
  max-width: 100%;
}

.max-width-103px{
  max-width: 103px;
}

@include media-breakpoint-down(sm) {
  .topbar-left-menu {
    display: none;
  }

  .button-menu-mobile {
    color: white;
  }

  .navbar-custom {
    background-color: $darkBlue;
    box-shadow: unset;
    color: white;
    text-align: center;
  }

  .nav-user {
    .account-user-avatar.lg {
      display: none !important;
    }

    .account-user-avatar.sm {
      display: block !important;
    }
  }

  .topbar-middle-logo {
    display: inline-block !important;
    height: 70px;
    text-align: center;

    .center-helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    .topnav-logo-sm {
      img {
        vertical-align: middle;
        max-height: 17px;
      }
    }
  }
}

// Enlarge menu
body[data-leftbar-compact-mode='condensed'] {
  min-height: 1600px;

  .wrapper {
    // Content Page
    .content-page {
      margin-left: 260px;
    }

    //Navbar & Footer
    .navbar-custom,
    .footer {
      left: 260px !important;
    }
  }

  // Sidebar Menu
  .side-nav {
    .side-nav-item {
      position: relative;
      white-space: nowrap;

      .side-nav-link {
        padding: 15px 20px;
        min-height: 56px;
        transition: none;

        &:hover,
        &:active,
        &:focus {
          color: $menu-item-hover;
          font-weight: 500;
        }

        i {
          font-size: 1.125rem;
          margin-right: 20px;
          margin-left: 6px;
        }

        span {
          display: none;
          padding-left: 10px;
        }
      }

      &:hover {
        .side-nav-link {
          position: relative;
          width: 260px;
          color: $white;
          background: $bg-leftbar;

          span {
            display: inline;
          }
        }

        > ul {
          display: block !important;
          left: 0;
          position: absolute;
          width: 190px;
          height: auto !important;
          padding: 5px 0;
          z-index: 9999;
          background: $bg-leftbar;
          a {
            padding: 8px 20px;
            position: relative;
            width: 190px;
            &:hover {
              color: $menu-item-hover;
            }
          }

          ul {
            left: 190px;
            top: 0;
          }
        }
      }
    }
  }

  .logo {
    span.logo-lg {
      display: none;
    }
  }
}

.profile-dropdown {
  padding: 0;

  .dropdown-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.nav-user {
  background-color: transparent;
  border: 0px;
  .account-user-name {
    font-weight: 500;
    color: $gray-700;
  }

  .account-user-avatar.lg {
    display: block;
  }

  .account-user-avatar.sm {
    display: none;
  }

  .account-user-avatar {
    i {
      font-size: 20px;
      vertical-align: middle;
      height: 20px;
      width: 20px;
    }
  }
}

.page-aside-left {
  padding-left: 0px;
}

.no-wrap {
  word-wrap: unset;
  white-space: nowrap;
}

.btn.min-width,
.btn-primary {
  min-width: 100px;
}

.btn-blue {
  color: $white;
  background-color: $blue;
  border-color: $blue;
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0;
}
.btn-rotessa-secondary,
.btn-rotessa-secondary.dropdown-toggle,
.react-bootstrap-table-pagination a.page-link {
  border-color: #9eb0c0;
  color: #9eb0c0;
  background-color: transparent;
  &:hover,
  &:focus,
  &:active {
    border-color: #9eb0c0 !important;
    background-color: #9eb0c0 !important;
    color: $white !important;
    box-shadow: none !important;
  }
}
@include media-breakpoint-down(sm) {
  .react-bootstrap-table-pagination-list .pagination {
    justify-content: flex-end;
  }
}

.react-bootstrap-table-pagination li.page-item.active a.page-link {
  border-color: #9eb0c0 !important;
  background-color: #9eb0c0 !important;
  color: $white !important;
  box-shadow: none !important;
}

.btn-rotessa-tertiary {
  border: 0;
  color: $gray-900;
  background-color: transparent;
  &:hover,
  &:focus,
  &:active {
    color: $gray-600;
    border: 0;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
.btn-rotessa-tertiary[disabled] {
  border: 0;
  color: $tertiary;
  background-color: transparent;
}

.react-bootstrap-table-pagination {
  .btn {
    font-size: $font-size-base;
  }
  .btn-secondary {
    color: $primary;
    background-color: none;
    border-color: $gray-200;
    box-shadow: none;
  }
  .react-bs-table-sizePerPage-dropdown {
    .btn-secondary {
      border-color: #9eb0c0;
      color: #9eb0c0;
      background-color: transparent;
      &:hover,
      &:focus,
      &:active {
        border-color: #9eb0c0 !important;
        background-color: #9eb0c0 !important;
        color: $white !important;
        box-shadow: none !important;
      }
    }
    .dropdown-menu {
      margin-top: 0.69rem;
    }
  }
}

.react-bootstrap-table {
  a {
    color: $gray-700;
    &:hover {
      color: $gray-700;
      text-decoration: underline;
    }
  }
  th {
    font-weight: 500;
  }
}

.client-dropdown {
  > .btn {
    padding: 10px 30px;
    box-shadow: none !important;
    width: 100%;
    font-size: 1rem;
    border-radius: 0;
    background-color: transparent !important;
    border: none;
    line-height: 1.5;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
    }
  }
  > .dropdown-menu {
    font-size: 1rem;
    transform: translate3d(0px, 54px, 0px) !important;
    margin: 0;
    width: 100%;
    border-radius: 0;
    margin-top: -10px;
    max-height: 500px;
    overflow-y: auto;
  }

  .dropdown-item.client-dropdown-status-Closed {
    color: $text-muted;
  }

  .dropdown-item.ellipsis,
  .dropdown-toggle div.ellipsis {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: unset;
    white-space: nowrap;
  }

  .dropdown-toggle {
    div.ellipsis {
      vertical-align: middle;
      display: inline-block;
      max-width: 90%;
    }
    &::after {
      vertical-align: 0.2em !important;
    }
  }

  font-size: 1rem;
  width: 100%;
  background-color: $lightBlue !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $darkBlue !important;
  }

  img.client-integration-logo {
    max-width: 20px;
    margin-right: 10px;
    vertical-align: -5px;
  }
}

.page-title-box {
  .page-title {
    font-size: 32px;
    font-weight: 500;
  }
  .page-title.page-title-wrap {
    white-space: normal !important;
    line-height: 1.1;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.card {
  .header-title {
    letter-spacing: 0.04em;
    font-weight: 300;
  }

  .card-image-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img{
      width: min(100%, 150px);
      max-width: 150px;
      max-height: 150px;
      border-radius: 4px;
    }
  }
}

.timeline-item {
  .text {
    font-weight: 500;
  }

  .text-info {
    color: #5587b1 !important;
  }

  .text-secondary {
    color: $secondary !important;
  }

  .text-danger {
    color: $danger !important;
  }

  .text-green {
    color: $green !important;
  }

  .text-cyan {
    color: $cyan !important;
  }

  .bg-info-lighten {
    background-color: rgba(85, 135, 177, 0.25) !important;
  }

  .bg-green-lighten {
    background-color: rgba(55, 179, 74, 0.25) !important;
  }

  .bg-danger-lighten {
    background-color: rgba(250, 92, 124, 0.25) !important;
  }

  .bg-cyan-lighten {
    background-color: rgba(57, 175, 209, 0.25) !important;
  }

  .timeline-item-info {
    small {
      font-size: 13px !important;
    }
  }
}

form label {
  font-weight: 400;
}

.wrap-input,
.wrap-input .input-group-text {
  box-sizing: border-box;
  max-width: 100%;
  white-space: normal !important;
  word-break: break-all;
}

h3.home-page-stats {
  font-size: 2rem;
  font-weight: 500;
}

.badge.status-badge {
  padding: 0.6em 0.9em;
  font-size: 0.85em;
  min-width: 85px;
  font-weight: 500;
}

.badge.status-badge.totals {
  font-size: 1em;
}

.circle-badge {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
}

.dashboard-icon {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  margin-right: 5px;
}

.table-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

td.td-badge {
  padding-bottom: 0 !important;
  .badge.status-badge {
    margin-bottom: 0.5rem;
  }
}

td.td-fit-width-content {
  padding-right: 15px;
  white-space: nowrap;
  width: 1%;
}

.medium {
  color: $medium;
}

.non-selectable-row {
  background-color: rgba($red, 0.1);
  &:hover,
  &:focus,
  &:active {
    background-color: rgba($red, 0.1) !important;
  }
}

.non-selectable-integration-row {
  input[type="checkbox"] {
    display: none
  }
  color: $text-muted !important;
}

.non-selectable-integration-row.clickable-row {
  cursor: default;
}

.table-search-container {
  position: relative;
}
.table-search {
  position: absolute;
  z-index: 9;
  font-size: 20px;
  line-height: 38px;
  right: 10px;
  top: 2px;
  color: rbga($gray-500, 0.4);
}

.table-sm td.ellipsis {
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: unset;
  white-space: nowrap;
}

.table-sm td {
  white-space: nowrap;
}

.table-sm td.sticky-right {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: white;
}

.create-account-left {
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(13, 39, 70, 1)), $create-account-bg-img;
  background-position: top;
  background-size: cover;
}

.create-account-text {
  position: absolute;
  bottom: 0;
  margin: 3rem;
}

.create-account-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
}
.create-account-logo {
  margin-top: 100px;
}
.create-account-face {
  width: 100%;
}

/* ONBOARDING */

.onboarding{
  color: $dark-grey;
  background-color: $blue-body-bg;
  a:not(.btn) {
    color: $darkBlue;
    text-decoration: underline;
  }
  a:hover {
    color: $light-grey;
  }
  .subtext {
    color: $light-grey !important;
    font-size: 1.1rem;
  }
  .emphasized-content {
    color: $dark-grey;
    font-style: italic;
    font-size: 1.2rem;
  }
  .text-muted {
    color: $light-grey !important;
  }

  form {
    label {
      color: $light-grey;
      margin-bottom: 0.4rem;
      font-size: 1.1rem;
    }
    .form-group {
      margin-bottom: 0.5rem;
    }
    .form-group > .form-group {
      margin-bottom: 0;
    }
    .form-control, select.form-control:not([size]):not([multiple]){
      color: $dark-grey;
      border-color: $primary;
      height: calc(3.3rem + 2px);
      padding: 0.6rem 1rem;
      &:disabled {
        background-color:rgba(245,245,245,255)
      }
    }
    .form-control.share-input {
      padding-right: 0.6em;
    }
    .form-control, select.form-control:not([size]):not([multiple]), .custom-radio-group, .custom-radio-label, .custom-radio-item {
      font-size: 1.35rem !important;
    }
    .form-check {
      label {
        margin-top: 2px;
        font-size: 1rem;
        line-height: 1.3rem;
      }
      label, .invalid-feedback {
        margin-left: 1.1rem;
      }
      .form-check-input {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
    .form-control::placeholder{
      color: $light-grey;
    }
    select.form-control:invalid, select.form-control option[value=""] {
      color: $light-grey  !important;
    }
    .invalid-feedback {
      font-size: 1rem;
    }
  }

  .copy{
    .form-control {
        border-radius: 0.15rem !important;
    }
    button {
      background-color: $primary;
      color: white;
      height: calc(3.3rem + 2px);
      font-size: 1.35rem;
    }
  }

  .next-button, .veriff-button, .demo-button {
    width: 100%;
    height: 3rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 500;
    background-color: $primary;
  }

  .demo-button {
    max-width: 150px;
  }
  
  .centering-block {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  
  .next-button img {
    padding-left: 0.5rem;
    vertical-align: middle;
    height: 0.85rem;
  }

  .onboarding-create-account-middle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh -	49px);
    padding-bottom: 49px;
  }

  .onboarding-create-account-right {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), $create-account-bg-img;
    background-position: top;
    background-size: cover;
  }

  .onboarding-create-account-right.col-lg-4 {
    padding: 0px;
  }

  .onboarding-create-account-text {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 4rem;
  }

  .onboarding-agree-link {
    color: $light-grey;
    font-weight: 300;
    font-size: 0.8rem;
    a {
      color: $primary;
      text-decoration: underline;
    }
    a:hover {
      color: $light-grey;
      text-decoration: underline;
    }
  }
}

.onboarding.onboarding-manager {
  .business-name {
    vertical-align: middle;
    display: inline;
    padding-right: 14.4px;
    color: $almost-black;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
  }

  .onboarding-top-bar {
    padding-right: 2em;
    padding-left: 2em;
  }

  .btn.btn-rotessa-tertiary {
    padding: 0px;
  }

  .btn.btn-onboarding-add-item {
    background-color: #FFFFFF;
    color: #414141;
    text-align: center;
    font-size: 1.35rem;
    border: none;
    width: 100%;

    span {
      vertical-align: middle;
    }    
    
    i { 
      padding:0px;
      font-size: 1.35rem;
    }
    
  }

  .btn.btn-onboarding-cancel {
    background-color: #ffffff00;
    color: #919191;
    border: none;
    height: 3rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    text-align: left;
    padding: 0rem;
    vertical-align: middle;

    img {
      padding-left: 0rem;
      padding-right: 0.5rem;
      vertical-align: middle;
      height: 0.85rem;
    }
  }

  .completed-icon {
    flex-basis: auto;
    height: 57px;
    border-radius: 100%;
    background: #6EB27B;
    color: $white;
    font-size: 1.9rem;
    font-weight: 500;
    padding: 0.2em 0.44em !important;
  }

  .pending-icon {
    flex-basis: auto;
    height: 57px;
    border-radius: 100%;
    background: $yellow;
    color: $white;
    font-size: 1.9rem;
    font-weight: 500;
    padding: 0.2em 0.44em !important;
  }

  .onboarding-left-pane {
    background-color: $primary;
  
    .onboarding-logo {
      height: calc($onboarding-topbar-height);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: $blue-body-bg solid 1px;
    }
  
    .onboarding-steps {
      display: flex;
      padding: 1em !important;
      justify-content: center;
  
      ol {
        color: $white;
        font-size: 1.4rem;
        list-style: none;
        counter-reset: item;
        vertical-align: top;
        padding-left: 0px;
        font-weight: 500;
        

        > li {
          counter-increment: item;
        }

        > li.active:before, > li.complete:before {
          opacity: 1;
        }

        > li.complete:before {
          font-family: 'Material Design Icons';
          content: '\FE6E';
          background: #6EB27B;
          color: $white;
        }
  
        > li:before {
          opacity: 0.5;
          content: counter(item);
          background: $white;
          border-radius: 100%;
          color: $primary;
          width: 1.8em;
          line-height: 1.8em;
          text-align: center;
          display: inline-block;
          margin: 0.8rem 1rem 0.8rem 0rem;
        }
        
        ul {
          font-size: 1.1rem;
          line-height: 2rem;
          list-style: none;
          padding-left: 0px;
          font-weight: 400;
          
          li {
            line-height: 2rem;
            display: flex;
            align-items: center;
          }

          li:not(.active, .pending, .failed), li > .inactive {
            opacity: 0.5;
          }

          > li:before {
            font-size: 2rem;
            content: '\2022';
            color: $white;
            width: 2rem;
            padding-bottom: 0.1rem;
            margin: 0 0.5rem 0 0.2rem;
            text-align: center;
            display: inline-block;
          }

          > li.pending:before, >li.failed:before {
            color: $yellow;
          }
        }
      }
    }
  }

  .onboarding-middle-pane {
    padding: 1em 2em;
    background-color: $blue-body-bg;
  }
  
  .onboarding-right-pane {
    padding: 1em 2em;
    background-color: $blue-body-bg;
    
    p {
      font-size: 1.1rem;
      font-weight: 300;
    }

    .why-we-ask-subtext{ 
      padding: 12px 24px 12px 24px;
      background-color: #eceeef;
      border-radius: 0.25rem;
      font-size: 1.0rem;
    }

  }
  .onboarding-promo-code .btn{
    font-size: 1.1rem;
  }
}

@include media-breakpoint-up(xl) {
  .onboarding.onboarding-manager {
    .onboarding-middle-pane {
      padding: 2em 2em $onboarding-topbar-height 6em !important;
    }

    .onboarding-right-pane {
      padding: 2em 4em $onboarding-topbar-height 6em !important;
    }
  }
}

@include media-breakpoint-up(lg) {
  .onboarding.onboarding-manager {
    min-height: 100vh;

    .onboarding-middle-pane {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: calc(100vh -	$onboarding-topbar-height);
      padding: 2em 2em $onboarding-topbar-height 2em;
    }

    .onboarding-right-pane {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: calc(100vh -	$onboarding-topbar-height);
      padding: 2em 2em $onboarding-topbar-height 2em;
    }
    .next-button {
      width: 160px;
    }
  }
}
/* END ONBOARDING */

/* MINIMAL FILE UPLOADER */
.minimal_file_uploader {
  .dropzone {
    min-height: 86px;
    color: $light-grey;
    border-color: $light-grey;
  }
  .dropzone .dz-message {
    padding: 10px;
  }
}
/* END MINIMAL FILE UPLOADER */

/* CUSTOM RADIO */

.custom-radio-group {  
  .form-control.is-invalid { 
    padding-right: 0px !important;
    background-image: none !important;
  }
  .custom-radio-input {
    border: 1px solid $almost-black;
    display: flex;
    justify-content: space-between;

    .custom-radio-label {
      margin-bottom: 0px;
      width: 100%;
      font-size: 1.2rem;
      padding: 0.6rem 1rem;
    }

    .custom-radio-append {
      display: flex;
      align-items: center;
    }
  }

  .custom-radio-input:last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .custom-radio-input:not(:last-child) {
    border-bottom: none;
  }
  
  .custom-radio-input:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

/* END CUSTOM RADIO */

.country-radio-item {
  display: flex;
  align-items: center;
  color: $dark-grey;

  svg {
    max-height: 1.4rem;
    margin-right: .6rem;
  }
}

.alert-with-icon {
  display: flex;
}

.inline-container {
  display: inline-block;
}

.inline-form-top-aligned {
  align-items: unset;
}

.bbb {
  background-image: url(https://seal-manitoba.bbb.org/logo/rbhzbum/rotessa-36713.png);
  background-size: 200px;
  width: 100px;
  height: 38px;
  margin: auto;
}
.bbb:hover {
  background-position: right;
}

.form-control:focus,
.form-control:active {
  border-color: $gray-600;
}

.input-group-append .form-control{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.mb-0-form-group .col .form-group {
  margin-bottom: 0;
}

.input-swap-link {
  font-size: 0.85rem;
}

.student-navbar {
  background-color: $white;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  min-height: 70px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1001;
}

.pending-homepage {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.3)),
    url('../../images/pending-homepage-background.svg');
  background-position: left top;
  background-size: cover;
}

/* CollapsibleText */

.collapsed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  position: relative;
}

.collapsed.fade-right::after {
  content:"";
  position:absolute;
  top:0px;
  right:0px;
  height:40px;
  width:25px;
  background: linear-gradient(90deg, transparent, white);
}
/* End CollapsibleText */

@media (min-width: 768px) {
  .flinksconnect {
    width: 100%;
    border: none;
  }
}

.gradient-card {
  background-image: linear-gradient(to right, #c7ffff, #c7e4ff);
}

.inline-block {
  display: inline-block;
}

.rotessa-fade {
  transition: opacity 0.2s;
}

/* Wizard Settings */
.menu-wizard {
  background-color: $lightBlue;
  margin: 1.5rem 1.5rem 0.5rem 1.5rem;
  padding: 1rem;
  color: white;

  .btn-rotessa-secondary.white {
    color: white;
    border-color: white;
  }

  p {
    font-size: 0.8rem;
  }
}
.dropdown-menu.narrow {
  min-width: 100px;
}

a.underline {
  text-decoration: underline;
}

.loading-container-min-height {
  min-height: 150px;
}

/* Authorization Settings */

.auth-setting-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  border: 2px dashed #9BA6AC;
  background-color: #FAFAFA;
  padding: 10px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.auth-preview-header {
  width: 100%;
  height: 25px;
  border: 1px solid $gray-300;
  border-bottom: none;
  background-color: #f3f3f3;
  border-radius: 0.25rem 0.25rem 0 0;
  display: flex;
  align-items: center;
}

.auth-preview-container {
  padding: 15px 15px 0px 15px;
  border: 1px solid $gray-300;
  background-color: $almost-white;
  border-radius: 0 0 0.25rem 0.25rem;
}

@include media-breakpoint-up(lg) {
  .auth-preview-controls {
    position: absolute;
    top: -40px;
    width: 100%;
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: $gray-300;
  border-radius: 50%;
  display: inline-block;
}

/* Processing Authorizations */
.processing-logo {
  max-height: 100px;
  width: 100%;
  margin: auto;
  img {
    max-height: 100px;
    max-width: 200px;
  }
}

.processing-content {
  .card-footer {
    background-color: #ebebeb;
    font-style: italic;
    font-size: 0.8rem;
  }
}

.processing-header-text {
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
}

/* Donation Authorizations */

.donation-header {
  padding: 0 $grid-gutter-width;
  background-color: $bg-topbar;
  box-shadow: $shadow;
  min-height: 100px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.03);
  min-width: 360px;
  .donation-header-contents {
    text-align: center;
    height: 100px;
    display: table;
    margin: 0 auto;
    .inner {
      height: 100px;
      padding: 0.375rem;
      display: table-cell;
      vertical-align: middle;
    }
    img {
      max-height: 100%;
      max-width: 276px;
    }
  }
}

.donation-content {
  width: 360px;
  margin: auto;
  padding: 125px 12px 0px;

  color: #656565;

  .invalid-feedback {
    font-size: 0.9rem;
  }
  .text-muted {
    color: #656565 !important;
  }
  a {
    color: #656565;
  }
  a:hover {
    color: $almost-black;
  }

  iframe {
    border-width: 0px;
  }

  .language-toggle {
    font-weight: 300;
  }

  .text-almost-black {
    color: $almost-black;
  }

  .flinks-label {
    font-size: 1.3rem;
  }

  .flinks-way-out {
    font-size: 0.8rem;
    font-style: italic;
  }

  .comment-description {
    max-width: 100%;
    text-align: left;
    white-space: normal;
  }

  .continue-btn {
    background-color: $almost-black;
    font-weight: $font-weight-bold;
    width: 100%;
    height: calc(3rem + 12px) !important;
    border: $almost-black 1px solid;
    border-radius: 8px;
    font-size: 1.3rem;

    i {
      position: relative;
      top: 1px;
    }
  }

  strong {
    color: $almost-black;
  }

  .card-footer {
    background-color: #ebebeb;
    font-style: italic;
    font-size: 0.8rem;
  }

  .custom-disabled {
    .form-control:disabled,
    .form-control::placeholder {
      border-color: $tertiary;
      color: $tertiary !important;
      opacity: 1;
    }
    label .text-muted {
      color: $tertiary !important;
    }
  }
}

.donation_agree_link {
  color: #656565;
  font-weight: 300;
  a {
    color: #656565;
  }
  a:hover {
    color: $almost-black;
  }
}

.card-no-background {
  box-shadow: none;
  background-color: transparent;
  margin-bottom: 0px;
}

/* CustomCarousel */

.carousel {
  position: relative;

  .carousel-control-arrow-left {
    position: absolute;
    top: 0px;
    left: -45px;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 30px;
    }
  }
  
  
  .carousel-control-arrow-right {
    position: absolute;
    top: 0px;
    right: -45px;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 30px;
    }
  }

  .carousel-indicators {
    bottom: -70px;
    li {
      background-color: $darkBlue;
    } 
  }
}


/* End CustomCarousel */

  select#address\.provinceCode:invalid {
    color: $tertiary !important;
  }

  .gray-input-background {
    .form-control {
      color: $gray-700;
      background-color: #FAFAFA;
    }
  }

/* PrependAvField */

.prepend-input-group,
.prepend-av-field {
  position: relative;

  .no-background {
    background-color: transparent;
    border: none;
  }

  .prepend-icon {
    position: absolute;
    left: 0px;
    top: 0px;
    height: calc(3rem + 12px) !important;
    z-index: 99;
    i {
      margin-top: 3px;
    }
  }

  .form-control {
    font-weight: 300;
    border: $tertiary 1px solid;
    color: $almost-black;
    border-radius: 8px;
    padding-left: 40px;
    height: calc(3rem + 12px) !important;
    font-size: 1.3rem;
  }

  .form-control.override-prepend {
    padding-left: 0.9rem;
    height: calc(2.3rem + 12px) !important;
  }

  .form-control-narrow {
    height: calc(3rem + 2px) !important;
  }

  .form-control-small-font {
    font-size: 1rem;
  }

  .form-control::placeholder,
  select#frequency:invalid,
  select#address\.provinceCode:invalid {
    color: $tertiary !important;
  }

  .form-control:focus {
    border-color: $almost-black;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .form-control:disabled {
    border-color: #d7d7d7;
    background-color: transparent;
  }

  .append-top {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
  }

  .append-bottom {
    border: $tertiary 1px solid;
    border-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
    margin-bottom: 1rem;
  }

  .light-background {
    background-color: $white !important;
  }
}

.donation-select {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.donation-select:after {
  content: '\f078';
  color: $tertiary;
  font-family: FontAwesome;
  font-weight: 100;
  font-size: 10px;
  right: 14px;
  top: 22px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}

@include media-breakpoint-down(sm) {
  .donation-content {
    .card-no-background .card-body {
      padding: 0.5em;
    }
  }
}

a.underline_hover {
  &:hover {
    color: $gray-700;
    text-decoration: underline;
  }
}

.rotessa-accordion {
  .card:not(:last-child) {
    border-bottom: 1px solid #eef2f7;
  }

  .card {
    box-shadow: none;
    .card-header:hover {
      cursor: pointer;
    }

    .card-header   {
      border-bottom: none;
    }

    .card-body   {
      padding-top: 0px;
    }
  }

  .custom-accordion-title {
    color: $gray-700;
    font-weight: 600;
  }

  .custom-accordion-title {
    .accordion-arrow.rotate {
      transform: rotate(90deg);
    }
  }

  .accordion-item-content {
    padding-left: 0px;
  }
}

.user-notification-accordion {
  .card {
    box-shadow: none;
  }

  .card.client-available {
    .card-header:hover {
      cursor: pointer;
    }
  }

  .custom-accordion-title {
    .accordion-arrow.rotate {
      transform: rotate(90deg);
    }
  }
  .client-unavailable .custom-accordion-title {
    color: $gray-600;
  }
}

.scroll-table-wrapper {
  height: 60vh;
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 20px;
  min-height: 250px;

  thead tr:nth-child(1) th {
    background-color: white;
    position: sticky;
    border-top: none;
    border-right: none;
    border-left: none;
    margin: 0px;
    top: 0;
    z-index: 10;
  }
}

.clickable-row {
  cursor: pointer;
}

.vertically-centered {
  display: flex;
  align-items: center;
}

.editor-border {
  padding: 0px 15px;
  border: $gray-300 1px solid;
  border-radius: 0.25rem;
}

.card-label {
  font-size: 20px;
  margin-bottom: 0px;
}
.danger-message {
  width: 100%;
  font-size: 0.75rem;
  color: $red;
}

.danger-title {
  width: 100%;
  color: $red;
}

.btn-new-customer-actions {
  background-color: $almost-white !important;
  color: $primary !important;
  width: 100%;
  min-height: 70px;
  align-items: center;
  border-color: $almost-white;

  &:hover,
  &:focus,
  &:active {
    border-color: $primary !important;
    box-shadow: none !important;
    background-color: $almost-white !important;
    color: $primary !important;
  }
}

.btn-integration-selection {
  background-color: $white !important;
  color: $primary !important;
  width: 100%;
  min-height: 70px;
  align-items: center;
  border-color: $white;

  &:hover,
  &:focus,
  &:active {
    border-color: $primary !important;
    box-shadow: none !important;
    color: $primary !important;
  }
}

.btn-integration-img-grayscale {
  filter: grayscale(100%);
}

.integration-card {
  max-height: 300px;
  width: 250px;
}

@include media-breakpoint-up(sm) {
  .customer-flow-card {
    max-width: 230px;
  }

  .customer-flow-card-header {
    max-width: 230px;
    min-height: 140px;
  }
}

.customer-flow-integration-icons {
  max-width: 90%;
  max-height: 100px;
}
.add-recipient-button {
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.centered-dropdown .dropdown-menu {
  left: 25% !important;
  right: 25% !important;
}

.selected-filter {
  background-color: $light;
  border: 1px solid $primary;
}

.unselected-filter {
  background-color: $light;
  border: 1px solid $light;
}

.uppercase-thin {
  font-weight: $font-weight-normal;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: $font-size-base;
  margin-top: 0;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

td.td-fit-width-content-no-padding {
  white-space: nowrap;
  width: 1%;
}

.tall-btn {
  height: 65px !important;
}

.rounded-btn {
  border-radius: 0.25em !important;
}

.side-by-side-table-card {
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-height: 680px) {
  .side-by-side-table-card {
    max-height: calc(100vh - 300px);
  }
}

.side-by-side-tables-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.exterior-card-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: $font-size-base;
  margin-top: 0;
}

.btn-link.inline-text {
  padding: 0px;
  text-align: left;
  vertical-align: top;
}